
export const URLS = {
  HOME: '/',
  BOOKING_ESTIMATION: '/booking',
  BOOKING_ESTIMATION_SUBSCRIPTION: '/booking/subscription',
  BOOKING_ESTIMATION_USAGE_TIME: '/booking/usage-time',
  BOOKING_ESTIMATION_VEHICLE_OPTION: '/booking/vehicle-option',
  BOOKING_ESTIMATION_CONTRACT_TERMS1: '/booking/contract-terms1',
  BOOKING_ESTIMATION_CONTRACT_TERMS2: '/booking/contract-terms2',
  BOOKING_ESTIMATION_CONFIRM_ESTIMATE: '/booking/confirm-estimate',
  BOOKING_ESTIMATION_USER_INFORMATION: '/booking/user-information',
  BOOKING_ESTIMATION_PAYMENT_METHOD: '/booking/payment-method',
  BOOKING_ESTIMATION_CONFIRM_CONTRACT: '/booking/confirm-contract',
  BOOKING_ESTIMATION_COMPLETE_CONTRACT: '/booking/complete-contract',
  BOOKING_ESTIMATION_DOWNLOAD: '/booking/estimate-download',
  BOOKING_ESTIMATION_DOWNLOAD_DATAURL: '/booking/estimate-download-dataurl',
  BOOKING_ESTIMATION_CUSTOMIZE: '/booking/estimate-customize',
  BOOKING_MODUSIGN_REDIRECT: '/booking/modu-redirect',

  BOOKING_ESTIMATION_LOGIN: '/booking/login',

  DRIVER_DISPATCH: '/driverdispatch',
  DRIVER_DISPATCH_SUBSCRIPTION: '/driverdispatch/subscription',
  DRIVER_DISPATCH_USAGE_TIME: '/driverdispatch/usage-time',
  DRIVER_DISPATCH_BASIC_INFO_1: '/driverdispatch/basic-info1',
  DRIVER_DISPATCH_BASIC_INFO_2: '/driverdispatch/basic-info2',
  DRIVER_DISPATCH_BASIC_INFO_3: '/driverdispatch/basic-info3',
  DRIVER_DISPATCH_OPTION: '/driverdispatch/option',
  DRIVER_DISPATCH_ESTIMATE: '/driverdispatch/estimate',
  DRIVER_DISPATCH_ESTIMATE_DOWNLOAD: '/driverdispatch/estimate-download',
  DRIVER_DISPATCH_ESTIMATE_DOWNLOAD_DATAURL: '/driverdispatch/estimate-download-dataurl',
  DRIVER_DISPATCH_USER_INFORMATION: '/driverdispatch/user-information',
  DRIVER_DISPATCH_RECRUIT_REQUEST: '/driverdispatch/recruit-request',
  DRIVER_DISPATCH_CONFIRM_CONTRACT: '/driverdispatch/confirm-contract',
  DRIVER_DISPATCH_CONFIRM_DISPATCH_REQUEST: '/driverdispatch/confirm-dispatch-request',
  DRIVER_DISPATCH_COMPLETE_CONTRACT: '/driverdispatch/complete-contract',
  DRIVER_DISPATCH_TEST: '/driverdispatch/test',
  DRIVER_DISPATCH_REDIRECT: '/driverdispatch/modu-redirect',


  DRIVER_DISPATCH_MANAGE_LOGIN: '/driver-manage/login',
  DRIVER_DISPATCH_MANAGE_EMAIL_LOGIN: '/driver-manage/login/email-login',
  DRIVER_DISPATCH_MANAGE_EMAIL_LOGOUT: '/driver-manage/login/email-logout',
  DRIVER_DISPATCH_MANAGE_REGISTER: '/driver-manage/login/register',
  DRIVER_DISPATCH_MANAGE_MYPAGE: '/driver-manage/my-page',
  DRIVER_DISPATCH_MANAGE_HISTORY: '/driver-manage/driver-history',




  RESERVATION_CREATE: '/reservation/create-reservation',
  RESERVATION_MANAGE_PAYMENTS: '/reservation/manage-payments',
  RESERVATION_MY_PAGE: '/reservation/my-page',
  RESERVATION_HISTORY: '/reservation/reservation-history',
  RESERVATION_HISTORY_PDF: '/reservation/reservation-history-pdf',
  LOGIN: '/reservation/login',
  EMAIL_LOGIN: '/reservation/login/email-login',
  EMAIL_LOGOUT: '/reservation/login/email-logout',
  VERIFY_EMAIL: '/reservation/login/verify-email',
  VERIFY_PASSWORD: '/reservation/login/verify-password',
  REGISTER: '/reservation/login/register',
  KAKAO_LOGIN_PHONE_CHECK: '/reservation/login/kakao-phone-check',

  RESERVATION_KAKAO_LOGIN_REDIRECT: '/reservation/kakao-redirect',



}
