
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import WhiteBox from '../../atoms/WhiteBox';
import { customerData } from '../../../archive/temporaryData';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon'
import useCheckPC from '../../../hooks/useCheckPC';
import { useSelector, useDispatch } from 'react-redux';
import { getDriverDispatchLoginInfoCookieData, getMasterAccountCustomerNoDd, getMasterAccountEmailDd, isChkPw } from '../../../lib/Util'
import MyPageApi from '../../../lib/MyPageApi';
import { mypageAll } from '../../../reducers/MyPage';
import { isChkResultSuccess, isChkEmail } from '../../../lib/Util';
import Modal from '../../molecules/Modal';
import Input from '../../atoms/Input';
import { useForm } from 'react-hook-form'
import moment from 'moment';
import TimeInputSelect from '../../molecules/TimeInputSelect';
import { Tag } from '../../atoms/TagInput';

import axios from 'axios';
import { closeLoading, openLoading } from '../../../reducers/loadingRedux';

/**
 * 마이페이지
 * name : mypage
 * TODO: 고객 데이터로 값 대체, button events
 * */
const DriverDispatchManageMyPage = () => {
	const { isPC } = useCheckPC();



	const [businessInfoSubTitle, setBusinessInfoSubTitle] = useState('사업자 등록증 미등록')
	const [cardCount, setCardCount] = useState(0);
	const [vehicleCount, setVehicleCount] = useState(0);
	const [userCount, setUserCount] = useState(0)
	const [addressCount, setAddressCount] = useState(0)
	const [myPageInfo, setMyPageInfo] = useState({
		BusinessData: {
			RepresentativeName: '',
			BusinessCode: '',
			RepresentativeName: '',
			Address1: '',
			Address2: '',
		},
		DriverDispatchInfo: {
			Id: 1,
			CustomerNo: 0,
			ProductType: 1,
			ContractDate: "",
			OptionHex: 2147483647,
			GarageAddress: "",
			CompanyAddress: "",
			WorkStartDt: "08:30:00",
			WorkEndDt: "18:20:00"
		}
	})

	const {
		handleSubmit, setValue, getValues, register, setError, formState: { errors } } = useForm();

	const [settingModalStatus, setSettingModalStatus] = useState({
		BusinessDataFlag: false,
		CarInfoFlag: false,
		MemberFlag: false,
		AddressFlag: false,

		BusinessDataModifyFlag: false,
		CarInfoModifyFlag: false,
		MemberModifyFlag: false,
		AddressModifyFlag: false,

		BusinessDataDeleteFlag: false,
		CarInfoDeleteFlag: false,
		MemberDeleteFlag: false,
		AddressDeleteFlag: false,
	})

	const [addressObj, setAddressObj] = useState({
		Id: 0,
		CustomerNo: 0,
		Alias: '',
		Address: '',
		Lat: '',
		Lng: '',
	})

	const [mailTag, setMailTag] = useState([])

	const [carInfoObj, setCarInfoObj] = useState({
		CustomerNo: 0,
		CarModel: '',
		CarNumber: ''
	})

	const [memberObj, setMemberObj] = useState({
		UserId: '',
		UserPw: '',
		AccountType: 'email',
		CustomerName: '',
		Email: '',
		PhoneNumber: '',
		HomeAddress: '',
		ParentCustomerNo: 0,
		UseType: '0',
		Roles: 0,
		CustomerNo: 0,
	})

	const dispatch = useDispatch();

	const loginInfo = useSelector(state => state.Login);


	useEffect(() => {
		async function getMyPageData() {

			let result = await MyPageApi.GetMyPageDriverDispatch(getMasterAccountCustomerNoDd(loginInfo.UserPool))
			if (isChkResultSuccess(result)) {
				setMyPageInfo(result.Item)
				dispatch(mypageAll(result.Item))

				let tmpStartDt = result.Item.DriverDispatchInfo.WorkStartDt.split(':')
				let tmpEndDt = result.Item.DriverDispatchInfo.WorkEndDt.split(':')

				setValue('workStartTimeHour', tmpStartDt[0])
				setValue('workStartTimeMinute', tmpStartDt[1])
				setValue('workEndTimeHour', tmpEndDt[0])
				setValue('workEndTimeMinute', tmpEndDt[1])

				console.log('성공')

			}
			else {
				console.log('실패')
			}
		}
		getMyPageData();
	}, [])

	useEffect(() => {


		if (customerData?.customer?.businessRegistrationNumber) {
			setBusinessInfoSubTitle('사업자 등록증 등록 완료')
		}

		if (customerData?.card?.length > 0) {
			setCardCount(customerData.card.length);
		}

		if (customerData?.vehicle?.length > 0) {
			setVehicleCount(customerData.vehicle.length);
		}

		if (customerData?.userGroup?.length > 0) {
			setUserCount(customerData.userGroup.length);
		}

		if (customerData?.addressGroup?.length > 0) {
			setAddressCount(customerData.addressGroup.length);
		}
	}, [customerData])



	// 주소지 관리
	const setFormAddress = (e) => {
		console.log(e.target.id, e.target.value)
		setAddressObj({
			...addressObj,
			[e.target.id]: e.target.value
		})
	}
	const resetAddress = () => {
		setAddressObj({ Id: 0, CustomerNo: 0, Alias: '', Address: '', Lat: '', Lng: '', })
	}

	// 차량 관리
	const setFormCarInfo = (e) => {
		setCarInfoObj({
			...carInfoObj,
			[e.target.id]: e.target.value
		})
	}
	const resetCarInfo = () => {
		setCarInfoObj({ CustomerNo: 0, CarModel: '', CarNumber: '' })
	}

	// 멤버 관리
	const setFormMember = (e) => {
		setMemberObj({
			...memberObj,
			[e.target.id]: e.target.value
		})
	}
	const resetMember = () => {
		setMemberObj({
			UserId: '',
			UserPw: '',
			AccountType: 'email',
			CustomerName: '',
			Email: '',
			PhoneNumber: '',
			HomeAddress: '',
			ParentCustomerNo: 0,
			UseType: '0',
			Roles: 0,
			CustomerNo: 0,
		})
	}



	const modalStatusChange = (type, data, data2) => {
		switch (type) {
			case 'Business':
				setSettingModalStatus({
					...settingModalStatus,
					BusinessDataFlag: data
				})
				break;
			case 'CarInfo':
				setSettingModalStatus({
					...settingModalStatus,
					CarInfoFlag: data
				})
				break;
			case 'Address':
				setSettingModalStatus({
					...settingModalStatus,
					AddressFlag: data
				})
				break;
			case 'Member':
				setSettingModalStatus({
					...settingModalStatus,
					MemberFlag: data
				})
				break;

			case 'BusinessModify':
				setSettingModalStatus({
					...settingModalStatus,
					BusinessDataFlag: data,
					BusinessDataModifyFlag: data
				})
				break;
			case 'CarInfoModify':
				setSettingModalStatus({
					...settingModalStatus,
					CarInfoFlag: data,
					CarInfoModifyFlag: data
				})
				setCarInfoObj({
					...carInfoObj,
					...data2
				})
				break;
			case 'AddressModify':
				setSettingModalStatus({
					...settingModalStatus,
					AddressFlag: data,
					AddressModifyFlag: data,
				})
				setAddressObj({
					...addressObj,
					...data2
				})
				break;
			case 'MemberModify':
				setSettingModalStatus({
					...settingModalStatus,
					MemberFlag: data,
					MemberModifyFlag: data,
				})
				setMemberObj({
					...memberObj,
					...data2
				})
				break;
			case 'BusinessDelete':
				setSettingModalStatus({
					...settingModalStatus,
					BusinessDataFlag: data,
					BusinessDataDeleteFlag: data
				})
				break;
			case 'CarInfoDelete':
				setSettingModalStatus({
					...settingModalStatus,
					CarInfoFlag: data,
					CarInfoDeleteFlag: data
				})
				setCarInfoObj({
					...carInfoObj,
					...data2
				})
				break;
			case 'AddressDelete':
				setSettingModalStatus({
					...settingModalStatus,
					AddressFlag: data,
					AddressDeleteFlag: data,
				})
				setAddressObj({
					...addressObj,
					...data2
				})
				break;
			case 'MemberDelete':
				setSettingModalStatus({
					...settingModalStatus,
					MemberFlag: data,
					MemberDeleteFlag: data,
				})
				setMemberObj({
					...memberObj,
					...data2
				})
				break;
		}

	}

	const getStrUseType = (useType) => {
		let retData = '';
		if (useType === 1) {
			retData = '탑승자'
		}
		else {
			retData = '부커'
		}
		return retData
	}

	const getStrMainSub = (Type) => {
		let retData = '';
		if (Type === 'MASTER') {
			retData = '대표'
		}
		else {
			retData = '일반'
		}
		return retData
	}

	const ShowModalManage = (type, flag, status) => {
		if (type === 'Business') {
			return (
				<>
					{flag && <Modal
						title={'사업자 정보 변경'}
						subTitle={''}>
						<div className={classNames('main-set')}>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="이름" className="" name="name" value="" />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="이름" className="" name="name" value="" />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="이름" className="" name="name" value="" />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="이름" className="" name="name" value="" />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="이름" className="" name="name" value="" />
							</div>
						</div>
						<div className={classNames('button-set')}>
							<Button
								onClick={() => {
									modalStatusChange('Business', false);
								}}
							>
								닫기
							</Button>
							<Button
								styleType={'secondary'}
								onClick={async () => {
									await MyPageApi.SetMyPageAddress()
									alert('사업자 정보 변경 완료')
									modalStatusChange('Business', false);
								}}
							>
								추가하기
							</Button>
						</div>
					</Modal>}
				</>
			)
		}
		else if (type === 'CarInfo') {
			const getStrCarInfo = () => {
				let retData = {
					titleStr: '',
					btnStr: '',
					subTitleStr: ''
				}
				if (settingModalStatus.CarInfoModifyFlag) {
					retData = {
						...retData,
						titleStr: '출/퇴근 시간 수정',
						btnStr: '수정하기'
					}
				}
				else if (settingModalStatus.CarInfoDeleteFlag) {
					retData = {
						...retData,
						titleStr: '차량 삭제',
						btnStr: '삭제하기',
						subTitleStr: '아래 차량을 삭제하시겠습니까?'
					}
				}
				else {
					retData = {
						...retData,
						titleStr: '차량 추가',
						btnStr: '추가하기',

					}
				}
				return retData
			}

			return (
				<>
					{flag && <Modal
						title={getStrCarInfo().titleStr}
						subTitle={getStrCarInfo().subTitleStr}>
						<div className={classNames('main-set')}>
							<TimeInputSelect
								setValue={setValue}
								getValues={getValues}
								startHourRegister={'workStartTimeHour'}
								startMinuteRegister={'workStartTimeMinute'}
								target={'start'}
								onContextChange={() => { }}
								finalMsg={'부터'} />

							<TimeInputSelect
								setValue={setValue}
								getValues={getValues}
								startHourRegister={'workEndTimeHour'}
								startMinuteRegister={'workEndTimeMinute'}
								target={'end'}
								onContextChange={() => { }}
								finalMsg={'까지'} />

						</div>

						<div className={classNames('button-set')}>
							{/* 닫기 */}
							<Button
								onClick={() => {
									if (settingModalStatus.CarInfoModifyFlag) {
										modalStatusChange('CarInfoModify', false);
									}
									else if (settingModalStatus.CarInfoDeleteFlag) {
										modalStatusChange('CarInfoDelete', false);
									}
									else {
										modalStatusChange('CarInfo', false);
									}

									resetCarInfo()
								}}
							>
								닫기
							</Button>

							{/* 추가하기 or 수정하기 */}
							<Button
								styleType={settingModalStatus.CarInfoDeleteFlag ? 'warning' : 'secondary'}
								onClick={async () => {

									// Setting Data
									let result = {}
									if (settingModalStatus.CarInfoModifyFlag) {
										result = await MyPageApi.SetMyPageWorkTime(
											{
												Id: myPageInfo.DriverDispatchInfo.Id,
												WorkStartDt: `${getValues('workStartTimeHour')}:${getValues('workStartTimeMinute')}`,
												WorkEndDt: `${getValues('workEndTimeHour')}:${getValues('workEndTimeMinute')}`
											}
										)
									}
									else if (settingModalStatus.CarInfoDeleteFlag) {
										result = await MyPageApi.DeleteMyPageCarInfo(carInfoObj.Id)
									}
									else {
										result = await MyPageApi.SetMyPageCarInfo(getMasterAccountCustomerNoDd(loginInfo.UserPool), carInfoObj.CarModel, carInfoObj.CarNumber)
									}

									if (isChkResultSuccess(result)) {
										if (settingModalStatus.CarInfoModifyFlag) { alert('출/퇴근시간 변경이 완료되었습니다.') }
										else if (settingModalStatus.CarInfoDeleteFlag) { alert('차량 삭제가 완료되었습니다.') }
										else { alert('차량 추가가 완료되었습니다.') }

										let result2 = await MyPageApi.GetMyPageDriverDispatch(getMasterAccountCustomerNoDd(loginInfo.UserPool))
										if (isChkResultSuccess(result2)) {
											dispatch(mypageAll(result2.Item))
											setMyPageInfo(result2.Item)

											let tmpStartDt = result2.Item.DriverDispatchInfo.WorkStartDt.split(':')
											let tmpEndDt = result2.Item.DriverDispatchInfo.WorkEndDt.split(':')

											setValue('workStartTimeHour', tmpStartDt[0])
											setValue('workStartTimeMinute', tmpStartDt[1])
											setValue('workEndTimeHour', tmpEndDt[0])
											setValue('workEndTimeMinute', tmpEndDt[1])


										}
										else {
											console.log('실패')
										}

										if (settingModalStatus.CarInfoModifyFlag) { modalStatusChange('CarInfoModify', false); }
										else if (settingModalStatus.CarInfoDeleteFlag) { modalStatusChange('CarInfoDelete', false); }
										else { modalStatusChange('CarInfo', false); }

										resetCarInfo()
									}
								}}
							>
								{getStrCarInfo().btnStr}
							</Button>
						</div>

					</Modal>}
				</>
			)
		}
		else if (type === 'Address') {
			const getStrAddress = () => {
				let retData = {
					titleStr: '',
					btnStr: '',
					subTitleStr: ''
				}
				if (settingModalStatus.AddressModifyFlag) {
					retData = {
						titleStr: '주소지 수정',
						btnStr: '수정하기'
					}
				}
				else if (settingModalStatus.AddressDeleteFlag) {
					retData = {
						titleStr: '주소지 삭제',
						btnStr: '삭제하기',
						subTitleStr: '아래 주소지를 삭제하시겠습니까?'
					}
				}
				else {
					retData = {
						titleStr: '주소지 추가',
						btnStr: '추가하기',

					}
				}
				return retData
			}

			return (
				<>
					{flag && <Modal
						title={getStrAddress().titleStr}
						subTitle={getStrAddress().subTitleStr}>
						<div className={classNames('main-set')}>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="별명" id="Alias" onChange={setFormAddress} value={addressObj.Alias} disabled={settingModalStatus.AddressDeleteFlag} />
							</div>
							<div className='input'>
								<input autoComplete='new-password' type="text" placeholder="주소입력" id="Address" onChange={setFormAddress} value={addressObj.Address} disabled={settingModalStatus.AddressDeleteFlag} />
							</div>
						</div>
						<div className={classNames('button-set')}>
							{/* 닫기 */}
							<Button
								onClick={() => {
									if (settingModalStatus.AddressModifyFlag) {
										modalStatusChange('AddressModify', false);
									}
									else if (settingModalStatus.AddressDeleteFlag) {
										modalStatusChange('AddressDelete', false);
									}
									else {
										modalStatusChange('Address', false);
									}

									resetAddress()
								}}
							>
								닫기
							</Button>

							{/* 추가하기 or 수정하기 */}
							<Button
								styleType={settingModalStatus.AddressDeleteFlag ? 'warning' : 'secondary'}
								onClick={async () => {
									// validation check	
									if (!addressObj.Alias.length) {
										alert('별명을 입력해주세요.')
										return;
									}

									if (!addressObj.Address.length) {
										alert('주소를 입력해주세요.')
										return;
									}




									// Setting Data
									let result = {}
									if (settingModalStatus.AddressModifyFlag) {
										result = await MyPageApi.UpdateMyPageAddress(addressObj.Id, addressObj.Alias, addressObj.Address, '', '')
									}
									else if (settingModalStatus.AddressDeleteFlag) {
										result = await MyPageApi.DeleteMyPageAddress(addressObj.Id)
									}
									else {
										result = await MyPageApi.SetMyPageAddress(getMasterAccountCustomerNoDd(loginInfo.UserPool), addressObj.Alias, addressObj.Address, '', '')
									}

									if (isChkResultSuccess(result)) {
										if (settingModalStatus.AddressModifyFlag) { alert('주소 변경이 완료되었습니다.') }
										else if (settingModalStatus.AddressDeleteFlag) { alert('주소 삭제가 완료되었습니다.') }
										else { alert('주소 추가가 완료되었습니다.') }

										let result2 = await MyPageApi.GetMyPage(getMasterAccountCustomerNoDd(loginInfo.UserPool))
										if (isChkResultSuccess(result2)) {
											dispatch(mypageAll(result2.Item))
										}
										else {
											console.log('실패')
										}

										if (settingModalStatus.AddressModifyFlag) { modalStatusChange('AddressModify', false); }
										else if (settingModalStatus.AddressDeleteFlag) { modalStatusChange('AddressDelete', false); }
										else { modalStatusChange('Address', false); }

										resetAddress()
									}
								}}
							>
								{getStrAddress().btnStr}
							</Button>
						</div>
					</Modal>}
				</>
			)
		}
		else if (type === 'Member') {
			const getStrMember = () => {
				let retData = {
					titleStr: '',
					btnStr: '',
					subTitleStr: ''
				}
				if (settingModalStatus.MemberModifyFlag) {
					retData = {
						titleStr: '서비스 이용 인원 수정',
						btnStr: '수정하기'
					}
				}
				else if (settingModalStatus.MemberDeleteFlag) {
					retData = {
						titleStr: '서비스 이용 인원 삭제',
						btnStr: '삭제하기',
						subTitleStr: '아래 이용자를 삭제하시겠습니까?'
					}
				}
				else {
					retData = {
						titleStr: '서비스 이용 인원 초대',
						subTitleStr: '이메일 주소 입력 후 엔터키나 콤마, 세미콜론으로 등록해주세요',
						btnStr: '초대하기',

					}
				}
				return retData
			}

			return (
				<>
					{flag && <Modal
						title={getStrMember().titleStr}
						subTitle={getStrMember().subTitleStr}>
						<div className={classNames('main-set')}>


							{(!settingModalStatus.MemberModifyFlag && !settingModalStatus.MemberDeleteFlag) && (
								<div className='input'>
									<Tag onInsertTag={setMailTag} />
								</div>
							)}

							{settingModalStatus.MemberModifyFlag && (
								<>
									<div className='input'>
										<Input type="text" placeholder="이메일 아이디" id="UserId" onChange={setFormMember} value={memberObj.UserId} disabled={settingModalStatus.MemberDeleteFlag} />
									</div>
									<div className='input'>
										<Input type="password" placeholder="비밀번호" id="UserPw" onChange={setFormMember} value={memberObj.UserPw} disabled={settingModalStatus.MemberDeleteFlag} />
									</div>
									<div className='input'>
										<Input type="text" placeholder="이름" id="CustomerName" onChange={setFormMember} value={memberObj.CustomerName} disabled={settingModalStatus.MemberDeleteFlag} />
									</div>
									<div className='input'>
										<Input type="text" placeholder="전화번호" id="PhoneNumber" onChange={setFormMember} value={memberObj.PhoneNumber} disabled={settingModalStatus.MemberDeleteFlag} />
									</div>
									<div className='input'>
										<select id="UseType" onChange={setFormMember} value={memberObj.UseType} disabled={settingModalStatus.MemberDeleteFlag} >
											<option value="0">이용 타입 선택</option>
											<option value="1">탑승자</option>
											<option value="2">부커</option>
										</select>
									</div>
								</>
							)}

						</div>

						{/* 수정 시작 */}
						<div className={classNames('button-set')}>
							{/* 닫기 */}
							<Button
								onClick={() => {
									if (settingModalStatus.MemberModifyFlag) {
										modalStatusChange('MemberModify', false);
									}
									else if (settingModalStatus.MemberDeleteFlag) {
										modalStatusChange('MemberDelete', false);
									}
									else {
										modalStatusChange('Member', false);
									}

									resetMember()
								}}
							>
								닫기
							</Button>

							{/* 추가하기 or 수정하기 */}
							<Button
								styleType={settingModalStatus.MemberDeleteFlag ? 'warning' : 'secondary'}
								onClick={async () => {

									if (settingModalStatus.MemberModifyFlag) {
										// validation check	
										if (!isChkEmail(memberObj.UserId)) {
											alert('이메일 아이디를 입력해주세요.')
											return;
										}

										if (!isChkPw(memberObj.UserPw)) {
											alert('비밀번호는 대/소문자,특수문자 포함 8~10자 입니다.')
											return;
										}

										if (!memberObj.CustomerName.length) {
											alert('이름을 입력해주세요.')
											return;
										}

										if (!memberObj.PhoneNumber.length) {
											alert('전화번호를 입력해주세요.')
											return;
										}

										if (Number(memberObj.UseType) === 0) {
											alert('이용 타입을 선택해주세요')
											return;
										}
									}


									let result = {}
									if (settingModalStatus.MemberModifyFlag) {
										result = await MyPageApi.UpdateMember(memberObj.CustomerNo, memberObj.CustomerName, memberObj.UserId, memberObj.UserPw, memberObj.PhoneNumber, memberObj.UseType)
									}
									else if (settingModalStatus.MemberDeleteFlag) {
										result = await MyPageApi.DeleteMember(memberObj.CustomerNo)
									}
									else {

										dispatch(openLoading())
										if (mailTag.length >= 1) {
											let usename = getDriverDispatchLoginInfoCookieData()
											result = await MyPageApi.InviteCustomerDriverDispatch(mailTag, usename.CustomerName, getMasterAccountCustomerNoDd(loginInfo.UserPool), getMasterAccountEmailDd(loginInfo.UserPool))
											dispatch(closeLoading())
											//result = await MyPageApi.SetMember(memberObj.UserId, memberObj.UserPw, memberObj.CustomerName, memberObj.PhoneNumber, getMasterAccountCustomerNo(loginInfo.UserPool), memberObj.UseType)
										}
										else {
											alert('초대할 이메일 주소를 1개 이상 등록해주세요')
											dispatch(closeLoading())
											return
										}
									}

									if (isChkResultSuccess(result)) {
										if (settingModalStatus.MemberModifyFlag) { alert('이용자 변경이 완료되었습니다.') }
										else if (settingModalStatus.MemberDeleteFlag) { alert('이용자 삭제가 완료되었습니다.') }
										else { alert('초대 메일을 발송하였습니다.') }

										dispatch(openLoading())
										let result2 = await MyPageApi.GetMyPageDriverDispatch(getMasterAccountCustomerNoDd(loginInfo.UserPool))
										if (isChkResultSuccess(result2)) {
											dispatch(mypageAll(result2.Item))
										}
										else {
											console.log('실패')
										}
										dispatch(closeLoading())

										if (settingModalStatus.MemberModifyFlag) { modalStatusChange('MemberModify', false); }
										else if (settingModalStatus.MemberDeleteFlag) { modalStatusChange('MemberDelete', false); }
										else { modalStatusChange('Member', false); }

										resetMember()
									}
								}}
							>
								{getStrMember().btnStr}
							</Button>
						</div>
						{/* 수정 완료 */}


						{/* 





						<div className={classNames('button-set')}>
							<Button
								onClick={() => {
									modalStatusChange('Member', false);

								}}
							>
								닫기
							</Button>
							<Button
								styleType={'secondary'}
								onClick={() => {
									alert('서비스 이용 인원 추가')
									modalStatusChange('Member', false);
								}}
							>
								{getStrMember().btnStr}
							</Button>
						</div> */}
					</Modal>}
				</>
			)
		}
	}

	return (
		<div className={classNames('mypage')}>

			{ShowModalManage('Business', settingModalStatus.BusinessDataFlag)}
			{ShowModalManage('CarInfo', settingModalStatus.CarInfoFlag)}
			{ShowModalManage('Address', settingModalStatus.AddressFlag)}
			{ShowModalManage('Member', settingModalStatus.MemberFlag)}

			<div className={classNames('mypage-section')}>
				{(myPageInfo.BusinessData !== null) && (
					<WhiteBox
						title={'사업자 정보'}
						subTitle={businessInfoSubTitle}
						showDetailButton={isPC ? false : true}
					>
						<div className={classNames('user-info-wrap')}>
							<div className={classNames('user-info')}>
								<span>{myPageInfo?.BusinessData?.RepresentativeName}</span>
								<span>{myPageInfo?.BusinessData?.BusinessCode}</span>
								<span>{myPageInfo?.BusinessData?.RepresentativeName}</span>
								<span>{myPageInfo?.BusinessData?.Address1}</span>
								<span>{myPageInfo?.BusinessData?.Address2}</span>
							</div>
							<Button styleType={'text'} classes={'txt-regular grey-3'} onClick={() => modalStatusChange('Business', true)}>수정</Button>
						</div>
					</WhiteBox>
				)}

				{/* <WhiteBox
						title={'결제 카드'}
						subTitle={`${cardCount}건`}
						showDetailButton={isPC ? false : true}
						>
						{customerData.card.length > 0 && (
							<ul className={classNames('item-list')}>
							{customerData.card.map((card, index) => (
								<li key={index}>
								<span className={classNames('blue')}>
									{card.type === 'regular' && '정기 결제'}
									{card.type === 'warranty' && '보증 등록'}
								</span>
								<span>
									{card.cardName} {card.cardNum}
								</span>
								<Button styleType={'text'} classes={'txt-regular grey-3'}>삭제</Button>
								</li>
							))}
							</ul>
						)}
						</WhiteBox> */}

				<WhiteBox
					title={'출/퇴근 시간'}
					showDetailButton={isPC ? false : true}
				>
					<ul className={classNames('item-list line')}>
						<li>
							<span className={classNames('mid-small')}>
								{moment('2023-01-01 ' + myPageInfo.DriverDispatchInfo.WorkStartDt).format('HH:mm')}~
								{moment('2023-01-01 ' + myPageInfo.DriverDispatchInfo.WorkEndDt).format('HH:mm')}
							</span>
							<span>
								{/* {'vehicle.CarModel'} */}
							</span>
							<div>
								<Button
									styleType={'text'}
									classes={'txt-regular grey-3'}
									onClick={() => modalStatusChange('CarInfoModify', true, {})}>
									수정
								</Button>
							</div>
						</li>
					</ul>

				</WhiteBox>
			</div>

			<div className={classNames('mypage-section')}>
				<WhiteBox
					title={'서비스 이용 인원'}
					subTitle={`${myPageInfo?.MemberList?.length}/4 (명)`}
					showDetailButton={isPC ? false : true}
				>
					{myPageInfo?.MemberList?.length > 0 && (
						<ul className={classNames('item-list line mb-28 member')}>
							{myPageInfo.MemberList.map((user, index) => (
								<li key={index}>
									<div className={classNames('item-set', isPC && ' w-180')}>
										{/* <span className={classNames('small blue')}>
												{user.type === 'owner' && '대표 관리자'}
												{user.type === 'manager' && '일반 관리자'}
												{user.type === 'member' && '일반 이용자'}
											</span> */}
										<span className={classNames('xsmall blue')}>
											{getStrMainSub(user.MemberType)}
										</span>
										<span className={classNames('xsmall')}>
											{getStrUseType(user.UseType)}
										</span>
										<span className={classNames('small')}>
											{user.CustomerName}
										</span>
									</div>
									<div className={classNames('item-set justify-between', isPC ? 'w-180' : 'w-100')}>
										<span>
											{user.PhoneNumber}
										</span>
										<div>
											<Button
												styleType={'text'}
												classes={'txt-regular grey-3'}
												onClick={() => modalStatusChange('MemberModify', true, { ...user })}>
												수정
											</Button>
											<span style={{ color: '#999999' }}>&nbsp; | &nbsp;</span>
											<Button
												styleType={'text'}
												classes={'txt-regular grey-3'}
												onClick={() => modalStatusChange('MemberDelete', true, { ...user })}>
												삭제
											</Button>
										</div>
									</div>
								</li>
							))}
						</ul>

					)}
					{/* <div className={classNames('button-set mb-12')}>
						<Button classes={`padding-10 ${!isPC && 'txt-small'}`}>대표 관리자 인원 변경</Button>
						<Button classes={`padding-10 ${!isPC && 'txt-small'}`}>인원별 계정 관리</Button>
					</div> */}
					<Button styleType={'secondary'} classes={'padding-10 flex'} onClick={() => { modalStatusChange('Member', true) }}>
						<Icon icon={'plus'} />
						<span className={classNames('txt-regular white')}>초대하기</span>
					</Button>

				</WhiteBox>

				{/* <WhiteBox
					title={'주소지 관리'}
					subTitle={`${mypageInfo?.AddressList?.length}개`}
					showDetailButton={isPC ? false : true}
				>
					{mypageInfo?.AddressList?.length > 0 && (
						<ul className={classNames('item-list line mb-28 address')}>
							{mypageInfo?.AddressList.map((address, index) => (
								<li key={index}>
									<div className={classNames('item-set')}>
										<span className={classNames('xsmall blue')}>
											{address.Alias}
										</span>
										<span className={classNames('address')}>
											{address.Address}
										</span>
									</div>
									<div>
										<Button
											styleType={'text'}
											classes={'txt-regular grey-3'}
											onClick={() => modalStatusChange('AddressModify', true, { ...address })}>
											수정
										</Button>
										<span style={{ color: '#999999' }}>&nbsp; | &nbsp;</span>
										<Button
											styleType={'text'}
											classes={'txt-regular grey-3'}
											onClick={() => modalStatusChange('AddressDelete', true, { ...address })}>
											삭제
										</Button>
									</div>


								</li>
							))}
						</ul>
					)}
					<Button styleType={'secondary'} classes={'padding-10 flex'} onClick={() => { modalStatusChange('Address', true) }}>
						<Icon icon={'plus'} />
						<span className={classNames('txt-regular white')}>추가하기</span>
					</Button>
				</WhiteBox> */}
			</div>
		</div>
	)
}

export default DriverDispatchManageMyPage