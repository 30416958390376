import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import '../../../styles/bookingLayout.css';
import BookingBox from './BookingBox';
import { getLastURLSegment } from '../../../utils/parse';
import { useDriverDispatchOptionContext, withDriverDispatchProvider } from '../../organisms/DriverDispatchOptionContext';
import { DriverDispatchEstimateSteps, DriverDispatchEstimateStepsRecruit, DriverDispatchEstimateStepsDispatch } from '../../../utils/steps';
import Header2 from '../../molecules/Header2';
import Steps from '../../atoms/Steps';
import useCheckPC from '../../../hooks/useCheckPC';
import StepsDriverDispatch from '../../atoms/StepsDriverDispatch';
import ShareButton from '../../etc/ShareButton';
import KakaoButton from '../../etc/KakaoButton';
import { isDev } from '../../../lib/Util';


const DriverDispatchEstimation = () => {
    const { isPC } = useCheckPC();
    const location = useLocation();
    const { selectedOptions, setSelectedOptions } = useDriverDispatchOptionContext();
    const [currentStep, setCurrentStep] = useState([]);
    const [stepList, setStepList] = useState([])
    const [currentPathData, setCurrentPathData] = useState('')

    useEffect(() => {
        let StepsArr = {}
        if (selectedOptions?.subscription === 'recruit') {
            StepsArr = DriverDispatchEstimateStepsRecruit
            // console.log('StepsArr = DriverDispatchEstimateStepsRecruit')
        }
        else {
            StepsArr = DriverDispatchEstimateStepsDispatch
            // console.log('StepsArr = DriverDispatchEstimateStepsDispatch')
        }

        if (location.pathname) {
            const currentPath = location.pathname;

            const transformedPath = getLastURLSegment(currentPath);
            setCurrentPathData(transformedPath)

            const stepList = StepsArr[transformedPath]
            // console.log(stepList)
            setCurrentStep(stepList);
        }

        const uniqueStepTitles = Array.from(new Set(Object.values(StepsArr).map(step => step.title)));

        document.title = '모시러 채용/파견 웹 견적서'
        setStepList(uniqueStepTitles)
    }, [location, DriverDispatchEstimateStepsRecruit, DriverDispatchEstimateStepsDispatch, selectedOptions])


    return (
        <>
        { 
            (  
                (location?.pathname?.indexOf('estimate') >= 0) || 
                (location?.pathname?.indexOf('confirm-contract') >= 0) ||
                (location?.pathname?.indexOf('confirm-dispatch-request') >= 0)
            )
            
            ? null : 
            <>
                <ShareButton type={'driverdispatch'}/>
            </>
        }
        <KakaoButton />
        

        <div className={'booking-estimation'}>
            <Header2
                stepList={stepList}
                currentStep={currentStep}
                hasClose={true}
                hasPrevButton={true}
                title={'견적서 작성'}
                Subscription={selectedOptions?.subscription}
                CurrentPath={currentPathData} />
            <BookingBox>
                {!isPC && <StepsDriverDispatch currentStep={currentStep} stepList={stepList} />}
                <Outlet />
            </BookingBox>
        </div>
        </>
        
    );
};

export default withDriverDispatchProvider(DriverDispatchEstimation); 