import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import SelectItem from './SelectItem';
import SelectItemWrapper from './SelectItemWrapper';
import useCheckPC from '../../hooks/useCheckPC';

const Select = (
  {
    options,
    selectedOption,
    onOptionChange,
    classes
  }) => {
  const { isPC } = useCheckPC();
  const labelRef = useRef(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  /** Select 영역 밖 클릭시 닫힘 */
  useEffect(() => {
    const handleClickOutside = event => {
      if (labelRef.current && !labelRef.current.contains(event.target)) {
        setIsSelectOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSelectOpen]);

  const handleOpenSelect = e => {
    e.preventDefault();
    setIsSelectOpen(true);
  };

  return (
    <label ref={labelRef} onMouseDown={handleOpenSelect} className={classNames('select-set')}>
      <select
        className={classNames(classes && classes)}
        value={selectedOption}
        onChange={e => { e.defaultPrevented(); onOptionChange(e.target.value); }}
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {isSelectOpen && (
        <SelectItemWrapper>
          {options.map(option => (
            <SelectItem
              key={option}
              onClick={() => {
                setIsSelectOpen(false);
                onOptionChange(option);
              }}
              isSelected={option === selectedOption}
            >
              {option}
            </SelectItem>
          ))}
        </SelectItemWrapper>
      )}
    </label>
  );
};

export default Select;
