import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageTitle from '../../atoms/PageTitle';
import { getValueByTarget, VehiclesOptionCount } from '../../../utils/parse';
import {
  BookingProducts,
  ConfirmEstimateDescription,
  ContractPeriodOptions,
  NumberOfVehiclesOptions,
  PassengerOptions,
  PurposeOfContractOptions,
  RoleOfVehiclesOptions,
  UsageTimeOptions,
  CarAmountOptions,
  InsuranceOptions,
  PassengerPersonOptions,
  UsageTimeOptionsAnnual,
  UsageTimeOptionsMonthly
} from '../../../utils/EstimateFormData';
import Button from '../../atoms/Button';
import { URLS } from '../../../utils/urls';
import BookingFormSummary from '../../atoms/BookingFormSummary';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListBox from '../../atoms/ListBox';
import TotalAmount from '../../atoms/TotalAmount';
import useCheckPC from '../../../hooks/useCheckPC';
import ListWithBullet from '../../atoms/ListWithBullet';
import ListBoxWithIcon from '../../atoms/ListBoxWithIcon';
import moment from 'moment';
import { SetMmEstimateHistory } from '../../../lib/EstimateApi';
import { ESTIMATE_TYPE_MONTHLY_MOSILER, PAGE } from '../../../utils/constant';
import { getEstimateCookieDataAndDecrypt } from '../../../lib/Util';


/**
 * 견적 확인 페이지
 * name : confirmEstimate
 * */

const ConfirmEstimate = () => {
  const navigate = useNavigate();
  const { isPC } = useCheckPC();
  const { BookingOption, ContractMonthArray } = useSelector((state) => state);

  /** 상품명 **/
  const [subscription, setSubscription] = useState([]);
  /** 이용 시간**/
  const [usageTime, setUsageTime] = useState([]);
  /** 차량 옵션 **/
  const [vehicleOption, setVehicleOption] = useState([]);
  /** 계약 조건 **/
  const [periodOfContract, setPeriodOfContract] = useState([]);
  /** 이용 지역 **/
  const [serviceArea, setServiceArea] = useState([])
  /** 이용 정보 **/
  const [contractTerms2, setContractTerm2] = useState([]);

  const [ConfirmEstimateDescription2, SetConfirmEstimateDescription2] = useState([])


  useEffect(() => {
    if (BookingOption.subscription === 'annual') {
            
      setSubscription([
          {
              title: `${getValueByTarget(BookingProducts,BookingOption.subscription,'title')} ${getValueByTarget(UsageTimeOptionsAnnual,BookingOption.usageTime,'title')}`,
              price: getValueByTarget(BookingProducts,BookingOption.subscription,'price') + getValueByTarget(UsageTimeOptionsAnnual,BookingOption.usageTime,'price')
          }])
      }
      else {

          setSubscription([
              {
                  title: `${getValueByTarget(BookingProducts,BookingOption.subscription,'title')} ${getValueByTarget(UsageTimeOptions,BookingOption.usageTime,'title')}`,
                  price: getValueByTarget(BookingProducts,BookingOption.subscription,'price') + getValueByTarget(UsageTimeOptions,BookingOption.usageTime,'price')
              }])
          
      }



    /** 차량 옵션 **/
    const updatedVehicleOption = [
      {
        title: `등록 차량 대수: ${getValueByTarget(
          NumberOfVehiclesOptions,
          BookingOption.vehicleOption.numberOfVehicles,
          'title'
        )}`,
        price: getValueByTarget(
          NumberOfVehiclesOptions,
          BookingOption.vehicleOption.numberOfVehicles,
          'price'
        )
      },
      {
        title: `차량 가격: ${getValueByTarget(
          CarAmountOptions,
          BookingOption.vehicleOption.carAmount,
          'title'
        )}`,
        price: getValueByTarget(
          CarAmountOptions,
          BookingOption.vehicleOption.carAmount,
          'price'
        )
      },
      {
        title: `보험: ${getValueByTarget(
          InsuranceOptions,
          BookingOption.vehicleOption.insurance,
          'title'
        )}`,
        discount: getValueByTarget(
          InsuranceOptions,
          BookingOption.vehicleOption.insurance,
          'discount'
        )
      },
      // {
      //   title: `탑승 고객: ${getValueByTarget(
      //     PassengerPersonOptions,
      //     BookingOption.vehicleOption.passengerPerson,
      //     'title'
      //   )}`,
      //   price: getValueByTarget(
      //     PassengerPersonOptions,
      //     BookingOption.vehicleOption.passengerPerson,
      //     'price'
      //   )
      // },
      {
        title: `서비스: ${getValueByTarget(
          RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime),
          BookingOption.vehicleOption.roleOfVehicles,
          'title'
        )}`,
        price: getValueByTarget(
          RoleOfVehiclesOptions(BookingOption.subscription, BookingOption.usageTime),
          BookingOption.vehicleOption.roleOfVehicles,
          'price'
        )
      }]
    setVehicleOption(updatedVehicleOption)

    /** 계약 조건 **/
    const updatedPeriodOfContract = [
      {
        title: `계약 기간: ${getValueByTarget(
          ContractMonthArray.select,
          BookingOption.contractTerms1.periodOfContract,
          'title'
        )}`,
        discount: getValueByTarget(
          ContractMonthArray.select,
          BookingOption.contractTerms1.periodOfContract,
          'discount'
        )
      }
    ]
    setPeriodOfContract(updatedPeriodOfContract)

    /** 이용 지역 **/
    const updatedServiceArea = [
      {
        title: `차고지: 
          ${BookingOption?.contractTerms1?.serviceArea?.departure?.address1} 
          ${BookingOption?.contractTerms1?.serviceArea?.departure?.address2}`
      },
      BookingOption?.contractTerms1?.serviceArea?.destination?.address1 && {
        title: `도착 지역: 
          ${BookingOption.contractTerms1.serviceArea.destination.address1} 
          ${BookingOption.contractTerms1.serviceArea.destination.address2}`
      }
    ].filter(Boolean);
    setServiceArea(updatedServiceArea)

    /** 이용 정보 **/
    const updatedContractTerms2 = [
      { title: `이용 목적: ${getValueByTarget(PurposeOfContractOptions, BookingOption?.contractTerms2?.purposeOfContract, 'title')}` },
      { title: `탑승자: ${getValueByTarget(PassengerOptions, BookingOption.contractTerms2.passenger, 'title')}` },
      { title: `차량 번호 1: ${BookingOption.contractTerms2.vehicleName1}` },
      { title: `차량 번호 2: ${BookingOption.contractTerms2.vehicleName2}` },

    ]

    if (VehiclesOptionCount(BookingOption?.vehicleOption?.numberOfVehicles) === 3) {
      updatedContractTerms2.push({ title: `차량 번호 3: ${BookingOption.contractTerms2.vehicleName3}` })
    }

    setContractTerm2(updatedContractTerms2)


    SetConfirmEstimateDescription2([
      '본 견적 내 항목은 모두 VAT 별도입니다.',
      '실 탑승자 4인 까지를 기준으로 한 견적이며, 5인 이상 이용이 필요하신 경우 컨시어지로 문의 바랍니다.',
      '기본 이용시간 외 추가 이용도 가능하며, 시간당 단가가 반영되어 추가 청구됩니다.',
      '본 견적서 내 포함되지 않은 항목이라도 실제 서비스 이용에 따라 할증 요금이 사전 안내 후 청구될 수 있습니다.',
      `본 견적서의 내용은 ${moment().add(1, 'months').format('yyyy년 MM월 DD일')}까지 유효합니다.`
    ])

  }, [BookingOption])


  return (
    <>
      <div className={classNames('booking-wrap')}>
        <PageTitle title={'견적 확인'} subtitle={'견적을 확인해 주세요'} />
        <div className={classNames('confirm-booking-wrap')}>
          <div className={classNames('confirm-booking-content')}>
            {/** title **/}
            {(BookingOption.subscription === 'monthly') && <ListBoxWithIcon
              title={`월간 모시러`}
              downloadFlag={true}
              url={URLS.BOOKING_ESTIMATION_DOWNLOAD}
            />}

            {(BookingOption.subscription === 'annual') && <ListBoxWithIcon
              title={`연간 모시러`}
              downloadFlag={true}
              url={URLS.BOOKING_ESTIMATION_DOWNLOAD}
            />}


            {/** 상품명 **/}
            <ListBox
              title={'상품명'}
              listItem={subscription}
            />

            {/** 옵션 **/}
            <ListBox
              title={'옵션'}
              listItem={vehicleOption}
            />

            {/** 계약 조건 **/}
            {(BookingOption.subscription === 'monthly') && <ListBox
              title={'계약 조건'}
              listItem={periodOfContract}
            />}


            {/** 이용하실 지역 **/}
            <ListBox
              title={'이용하실 지역'}
              listItem={serviceArea}
            />

            {/** 이용 정보 **/}
            {/* <ListBox
              title={'이용 정보'}
              listItem={contractTerms2}
            /> */}

            <TotalAmount styleType={'secondary'} />

          </div>

          <BookingFormSummary
            hasAmonut={false}
            description={ConfirmEstimateDescription2}>
            <div className={classNames('button-set')}>
              <Button
                onClick={
                  () => navigate(URLS.BOOKING_ESTIMATION_VEHICLE_OPTION)
                }
              >이전</Button>
              <Button
                styleType={'secondary'}
                onClick={
                  () => {

                    // await하지 않고, 모시러 서버에 저장
                    SetMmEstimateHistory({
                      ...getEstimateCookieDataAndDecrypt(),
                      EstimateType: ESTIMATE_TYPE_MONTHLY_MOSILER,
                      EstimatePage: PAGE.PAGE_05,
                      JsonData: JSON.stringify(BookingOption)
                    })

                    navigate(URLS.BOOKING_ESTIMATION_USER_INFORMATION)

                  }
                }
              >다음</Button>
            </div>
          </BookingFormSummary>
        </div>
      </div>
      {!isPC && (
        <div className={classNames('box-light-grey-1 footer-contents')}>
          <ListWithBullet description={ConfirmEstimateDescription2} />
        </div>
      )}
    </>
  )
}

export default ConfirmEstimate;