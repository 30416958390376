import axios from "axios";
import moment from "moment";
const SLACK_URL = "https://hooks.slack.com/services/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";



export const SendSlackEstimate = (info) => {

    let text = `[${info.UserName}님이 견적 화면에 들어왔습니다.]
    - 상품명 : ${info.SelectedProductStr}
    - 담당자 성명 : ${info.UserName}
    - 담당자 Email : ${info.UserEmail}
    - 담당자 연락처 : ${info.UserPhone}
    - 차고지 : ${info.StartAddress}
    - 이용희망일정 : ${info.UsageSchedule}
    - 옵션
      1) 차량등록 : ${info.MmOptionVehicles}
      2) 차량모델 : ${info.MmOptionCarAmount}
      3) 보험 : ${info.MmOptionInsurance}
      4) 서비스 : ${info.MmOptionService}
    `

    let json = JSON.stringify({
        username: '월.모Bot(New)',
        text,
        channel: '#월간모시러-견적문의'
    })

    return new Promise(async (resolve, reject) => {
        if (Number(process.env.REACT_APP_IS_DEV) === 0) {
            let result = await axios({
                method: 'post',
                url: SLACK_URL,
                data: json
            })

            resolve(result)
        }
        else {
            resolve({})
        }

    })
}

export const SendSlackDdEstimate = (info) => {

    // 이인만님이 "[월간] 80시간 상품: 1일 최소 8시간 이상" 견적 문의 :경고: 
    let text = `${info.UserName}님이 "${info.SelectedProductStr}" 견적 문의`

    let json = JSON.stringify({
        username: '채용파견',
        text,
        channel: '#채용파견-견적문의'
    })

    return new Promise(async (resolve, reject) => {
        if (Number(process.env.REACT_APP_IS_DEV) === 0) {
            let result = await axios({
                method: 'post',
                url: SLACK_URL,
                data: json
            })

            resolve(result)
        }
        else {
            resolve({})
            console.log(json)
        }

    })
}


export const SendSlackContracting = (info) => {

    let text = `[${info.ContractName}님이 계약 초안 화면에 들어왔습니다.]
    - 고객구분 : ${info.UserType}
    - 상품명 : ${info.ContractProduct}
    - 계약자 성명 : ${info.ContractName}
    - 담당자 성명 : ${info.BookerName}
    - 담당자 Email : ${info.BookerEmailAddress}
    - 담당자 연락처 : ${info.BookerPhoneNumber}
    - 희망계약기간 : ${info.ContractMonth}
    - 이용희망일정 : ${info.UsageDate}
    ${info.UserType === '법인' ? `    - 사업자등록증링크 : ${info.BusinessLicense}` : ``}`

    let json = JSON.stringify({
        username: '월.모Bot(New)',
        text,
        channel: '#월간모시러-계약중'
    })

    return new Promise(async (resolve, reject) => {
        if (Number(process.env.REACT_APP_IS_DEV) === 0) {
            let result = await axios({
                method: 'post',
                url: SLACK_URL,
                data: json
            })

            resolve(result)
        }
        else {
            resolve({})
        }

    })
}

export const SendSlackDdContracting = (info) => {

    let text = `${info.UserType} - ${info.ContractName}님 ${info.ContractProduct} 계약서 확인중`

    let json = JSON.stringify({
        username: '채용파견',
        text,
        channel: '#채용파견-계약문의'
    })

    return new Promise(async (resolve, reject) => {
        if (Number(process.env.REACT_APP_IS_DEV) === 0) {
            let result = await axios({
                method: 'post',
                url: SLACK_URL,
                data: json
            })

            resolve(result)
        }
        else {
            resolve({})
            console.log(json)
        }

    })
}

export const SendSlackDdContractComplete = (info) => {

    let text = `${info.UserType} - ${info.ContractName}님 ${info.ContractProduct} 계약서 작성 완료`

    let json = JSON.stringify({
        username: '채용파견',
        text,
        channel: '#채용파견-계약작성완료'
    })

    return new Promise(async (resolve, reject) => {
        if (Number(process.env.REACT_APP_IS_DEV) === 0) {
            let result = await axios({
                method: 'post',
                url: SLACK_URL,
                data: json
            })

            resolve(result)
        }
        else {
            resolve({})
            console.log(json)
        }

    })
}

export const SendSlackDdContractComplete2 = (info) => {

    let text = `${info.UserType} - ${info.ContractName}님 ${info.ContractProduct} 파견 의뢰서 작성 완료`

    let json = JSON.stringify({
        username: '채용파견',
        text,
        channel: '#채용파견-계약작성완료'
    })

    return new Promise(async (resolve, reject) => {
        if (Number(process.env.REACT_APP_IS_DEV) === 0) {
            let result = await axios({
                method: 'post',
                url: SLACK_URL,
                data: json
            })

            resolve(result)
        }
        else {
            resolve({})
            console.log(json)
        }

    })
}




export const SendSlack2 = (info, CustomerNo) => {

    let text = `✨ ${info.ContractName}님 월간모시러 계약문의 응답 ✨
    - 담당자 이름 : ${info.BookerName}
    - 담당자 Email : ${info.BookerEmail}
    - 담당자 연락처 : ${info.BookerPhone}`

    let json = JSON.stringify({
        username: '월간모시러봇',
        text,
        channel: '#월간모시러-계약중'
    })

    return new Promise(async (resolve, reject) => {
        let result = await axios({
            method: 'post',
            url: SLACK_URL,
            data: json
        })

        resolve(result)
    })
}

export const SendSlackContractComplete = (obj) => {

    let text = `✨${obj.UserName} 고객 ${obj.ProductName} 계약서 작성 완료!✨
    (${obj.UserName} / ${obj.PhoneNumber} / ${obj.EmailAddress} / ${moment().format('yyyy-MM-DD HH:mm')}) / 결제기한 : ${moment().add(3, 'days').format('yyyy-MM-DD')} 17:00)`

    let json = JSON.stringify({
        username: '월간모시러봇',
        text,
        channel: '#월간모시러-계약작성완료'
    })

    return new Promise(async (resolve, reject) => {
        if (Number(process.env.REACT_APP_IS_DEV) === 0) {
            let result = await axios({
                method: 'post',
                url: SLACK_URL,
                data: json
            })

            resolve(result)
        }
        else {
            resolve({})
        }

    })
}
